<template>
  <div>
    <div class="checkout_container">
      <!-- <p class="checkout_container_title">{{ $t("credit card") }}</p> -->
      <form @submit.prevent="paynow" id="payment-form" method="POST" action="https://merchant.com/charge-card">
        <div class="form-group">
          <div v-if="formError" class="error-container">
            <p class="error">{{ formError }}</p>
          </div>
        </div>
        <div class="one-liner" v-if="!loader">
          <label class="label-grey" for="card-number">{{ $t("Existing card number") }}</label>
          <p>XXXX XXXX XXXX {{ existingCardDetails.carddetails.last4 }}</p>
          <br />

          <label for="card-number label-grey">{{ $t("Card number") }}</label>
          <div class="input-container card-number">
            <div class="icon-container">
              <img id="icon-card-number" src="@/assets/icons/card.svg" alt="PAN" />
            </div>
            <div
              class="card-number-frame"
              :class="[localDisplayLang === 'ara' ? 'card-number-frame-ara' : 'card-number-frame']"
            ></div>
            <div class="icon-container payment-method">
              <img id="logo-payment-method" />
            </div>
            <div class="icon-container">
              <img id="icon-card-number-error" src="@/assets/icons/error.svg" />
            </div>
          </div>

          <div class="date-and-code">
            <div>
              <label for="expiry-date label-grey">{{ $t("Expiry date") }}</label>
              <div class="input-container" :class="localDisplayLang === 'ara' ? 'rtl-expiry-date' : 'expiry-date'">
                <div class="icon-container">
                  <img id="icon-expiry-date" src="@/assets/icons/exp-date.svg" alt="Expiry date" />
                </div>
                <div
                  class="expiry-date-frame"
                  :class="[localDisplayLang === 'ara' ? 'expiry-date-frame-ara' : 'expiry-date-frame']"
                ></div>
                <div class="icon-container">
                  <img id="icon-expiry-date-error" src="@/assets/icons/error.svg" />
                </div>
              </div>
            </div>

            <div>
              <label class="label-grey" for="cvv">{{ $t("Security code") }}</label>
              <div class="input-container cvv">
                <div class="icon-container">
                  <img id="icon-cvv" src="@/assets/icons/cvv.svg" alt="CVV" />
                </div>
                <div class="cvv-frame" :class="[localDisplayLang === 'ara' ? 'cvv-frame-ara' : 'cvv-frame']"></div>
                <div class="icon-container">
                  <img id="icon-cvv-error" src="@/assets/icons/error.svg" />
                </div>
              </div>
            </div>
          </div>

          <!-- add submit button -->
          <button class="button-primary primary-net5-button button-margin">
            {{ $t("update") }}
          </button>
        </div>
      </form>
      <div class="loader" v-if="formLoader">
        <Loading></Loading>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { eventBus } from "@/eventBus";

export default {
  props: {
    existingCardDetails: {
      type: Object,
    },

    isUpdateFailed: {
      type: Boolean,
    },
  },

  components: {
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  },

  data() {
    return {
      loader: null,
      localDisplayLang: null,
      formError: null,
      formLoader: false,
      paymentSuccess: null,
      cardUpdateFailed: false,
    };
  },

  watch: {
    formError(val) {
      if (val) {
        setTimeout(() => {
          this.formError = null;
        }, 5000);
      }
    },
  },

  computed: {
    ...mapGetters(["subscriptionList", "getGatewayList"]),
  },

  created() {
    

    eventBus.$on("paymentconfirmation-response", (response) => {
      const theFlowState = {
        subscriptionList: this.subscriptionList,
        isCardUpdate: true,
      };

      if (response.reason) {
        this.formLoader = false;
        this.formError = response.reason;
      } else {
        if (response.redirect_url) {
          localStorage.setItem("cardUpdateFlowState", JSON.stringify(theFlowState));

          window.open(response.redirect_url, "_self");
        }
        this.formLoader = true;
      }
    });

    setTimeout(() => {
      this.initializeFrames();
    }, 100);
  },

  mounted() {
    
    
    

    if (this.isUpdateFailed) {
      this.cardUpdateFailed = this.isUpdateFailed;
    }

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
  },

  methods: {
    ...mapActions(["paymentInitiation", "paymentConfirmation"]),

    retryPaymentConfirm() {},

    goHome() {
      this.$router.push("/");
    },

    initializeFrames() {
      let self = this;

      Frames.init({
        publicKey: self.getPublicAPIkey().apiKey,
        localization: {
          cardNumberPlaceholder: "Card Number",
          expiryMonthPlaceholder: "MM",
          expiryYearPlaceholder: "YY",
          cvvPlaceholder: "CVV",
        },
        style: {
          base: {
            color: "white",
          },
          valid: {
            color: "green",
          },
          invalid: {
            color: "red",
          },
        },
      });

      Frames.addEventHandler(Frames.Events.CARD_TOKENIZED, function(event) {
        
        self.checkoutToken = event.token;

        if (self.checkoutToken) {
          self.paymentInit();
        }
      });

      Frames.addEventHandler(Frames.Events.CARD_TOKENIZATION_FAILED, function() {
        self.onCardTokenizationFailed();
      });
    },

    getPublicAPIkey(gateway = "CHECKOUT") {
      let apiKey = "";
      let pgEnv = "";
      let gatewayId = "";

      

      if (gateway) {
        this.getGatewayList.forEach((gatewayDetail) => {
          if (gatewayDetail.gatewayid === gateway) {
            apiKey = gatewayDetail.gwcredential.ApiPublicKey;
            pgEnv = gatewayDetail.gwcredential.pgEnv;
            gatewayId = gatewayDetail.gatewayid;
          }
        });
      }

      return { apiKey, pgEnv, gatewayId };
    },

    paynow() {
      this.formLoader = true;

      if (!Frames.isCardValid()) {
        this.formError = "Not a valid card";
        this.formLoader = false;
      } else {
        Frames.submitCard();
      }
      
    },

    paymentInit() {
      

      let payload = {
        gatewayid: this.getPublicAPIkey().gatewayId,
        params: {
          amount: 0,
          currency: this.subscriptionList[0].currency,
          devicetype: "WEB",
          transactionpurpose: "VERIFY",
          transactionmode: "CC",
          availabilityid: "this.paymentDetail.detail.availabilityset[0]",
          planid: this.subscriptionList[0].planid,
        },
      };

      

      // this.loader = true;
      this.paymentInitiation(payload)
        .then((response) => {
          this.formLoader = false;
          if (!response.reason) {
            this.referenceData = response.data;

            

            let callbackPayload = {
              gatewayid: this.getPublicAPIkey().gatewayId,
              referencedata: this.referenceData,
              checkoutToken: this.checkoutToken,
            };

            
            this.checkoutCallback(callbackPayload);
          } else {
            this.formError = response.reason;
          }
        })
        .catch((error) => {
          this.formLoader = false;
          
        });
    },

    checkoutCallback(response) {
      let currentDomain = window.location.origin;
      let referencedata = response.referencedata;
      referencedata.referencedata.chargeid = response.checkoutToken;
      referencedata.referencedata.return_url =
        currentDomain +
        "/profile" +
        "?" +
        "planid=" +
        response.referencedata.referencedata.planid +
        "&" +
        "referenceid=" +
        response.referencedata.referenceid;

      let payload = {
        gatewayid: response.gatewayid,
        params: {
          referencedata: referencedata,
        },
      };

      eventBus.$emit("paymentConfirmation", payload);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

// .label-grey {
//   color: #848486 !important;
// }

.one-liner {
  label {
    margin: 1rem 0;
  }
  p {
    margin: 0.5rem 0;
  }
}

.date-and-code {
  margin: 1.5rem 0;
}

.button-margin {
  margin: 1.5rem 0;
}

// Message
.message_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;

  .icon {
    width: 50px;
    height: auto;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
      text-align: center;
      width: 75%;
      margin: 2.5rem 0;
      line-height: 1.5;
    }
  }
}

.button-primary {
  width: 25%;
}

// @media only screen and (max-width:500px) {
//   .button-primary {
//     width: 100%;
//   }
// }

// Update Card

.loader {
  margin: 2rem 0;
}

.checkout_container {
  // padding: 4rem 2rem 3rem;
  max-width: 55%;
  padding: 1.4rem;
  &_title {
    font-family: $font-regular;
    color: #fff;
    margin: 20px 0;
  }
}
.card_height {
  height: 45px;
  border: 0.5px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  padding: 0.6rem;
}
.flex_container {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
}
button {
  margin-top: 20px;
  width: 100%;
  padding: 0.6rem;
}
.form_loader {
  margin-top: 20px;
}
.payment_success {
  font-family: $font-regular;
  text-align: center;
  color: #fff;
  margin: 20px 0;
}
.subscription-success-card {
  border: 1px solid #32363e;
  border-radius: 10px;
  background-color: #191b20;
  position: relative;
  padding: 0.6rem;
  text-align: left;
  .tick {
    position: absolute;
    top: -5px;
    right: -5px;
  }
  .plan-title {
    color: #f8fafd;
    font-family: $font-regular;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 23px;
  }
  .plan-type {
    color: #70787f;
    font-family: $font-regular;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 27px;
  }
  .plan-interval {
    color: #4d7bff;
    font-family: $font-regular;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
  }
}

//frames css styles
label {
  display: block;
  height: 10px;
  color: #848486;
  font-size: 14px;
  font-weight: 500;
  line-height: 10px;
  margin: 8px 0;
}
.date-and-code {
  display: flex;
  margin-bottom: 8px;
}
.date-and-code > div:nth-child(1) {
  width: 55.715%;
}
.date-and-code > div:nth-child(2) {
  width: 45.719%;
}
.input-container {
  position: relative;
  display: flex;
  height: 40px;
}
.icon-container:last-child {
  right: 0;
}
.icon-container.payment-method {
  right: 0;
}

.input-container.card-number {
  margin-bottom: 8px;
}
.input-container.expiry-date {
  margin-right: 8px;
}

.input-container.rtl-expiry-date {
  margin-left: 8px;
}

#icon-expiry-date {
  width: 20px;
}

#icon-cvv {
  width: 20px;
}

.card-number-frame,
.expiry-date-frame,
.cvv-frame {
  flex: 1 1 auto;
  padding-left: 40px;
}
.card-number-frame-ara,
.expiry-date-frame-ara,
.cvv-frame-ara {
  flex: 1 1 auto;
  padding-left: 10px;
}

div + button {
  margin-top: 8px;
}
.icon-container {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 26px;
  margin: 0 7px;
}
.icon-container.payment-method {
  transform: translateY(-50%) rotateY(90deg);
  transition: opacity 0.15s ease-out;
  opacity: 0;
  top: 50%;
}

.icon-container.payment-method.show {
  opacity: 1;
  transition: all 0.4s ease-out;
  transform: translateY(-50%) rotateY(0deg);
}

.icon-container.payment-method img {
  width: 100%;
}

[id$="-error"] {
  display: none;
}

.frame {
  opacity: 0;
}

.frame--activated {
  opacity: 1;
  border: solid 1px rgba(255, 255, 255, 0.3);
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.2);
}
.frame--activated.frame--focus {
  border: solid 1px rgba(255, 255, 255, 0.3);
  box-shadow: 0 2px 5px 0 rgba(19, 57, 94, 0.15);
}

.frame--activated.frame--invalid {
  border: solid 1px #d96830;
  box-shadow: 0 2px 5px 0 rgba(217, 104, 48, 0.15);
}

.error-message {
  display: block;
  color: #c9501c;
  font-size: 0.9rem;
  margin: 8px 0 0 1px;
  font-weight: 300;
}
@media screen and (min-width: 300px) and (max-width: 576px) {
  .checkout_container {
    max-width: 100%;
  }

  .button-primary {
    width: 50%;
  }
}

@media only screen and (max-width:500px) {
  .checkout_container {
    margin: 0 0rem;
    width: 100%;
  }

  .button-primary {
    width: 100%;
  }
}

@media only screen and (min-width: 501px) and (max-width:1000px) {
  .checkout_container {
    margin: 0 0rem;
    width: 50%;
  }

  .button-primary {
    width: 50%;
  }
}
</style>
